
.App {
    background: linear-gradient(#484848, #000000);
    background: url("./Assets/gun_metal.webp");
    font-family: 'Inter', sans-serif;
}

.navbar {
    height: 10vh;
    background: url("./Assets/gun_metal.webp");
    background-color: #000000;
}

.navbar p {
    color: #DC6F0B;
    text-transform: uppercase;
}

#basic-nav-dropdown::after{
    display: none; 
 }

.social-link {
    color: #DC6F0B;
}
.navbar a {
    color: black;
    text-align:center
    
}

.about-me {
    background: linear-gradient(#484848, #000000);
    background: url("./Assets/gun_metal.webp");
}

.about-photo {
    height: 50vh;
    background-image: url("https://d3ee098cruiy0.cloudfront.net/BWDrive.jpg");
    background-size: cover;
}

.about-header {
    margin: 0;
    padding: 0;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.about-header h1 {
    font-size: 3rem;
    font-family: 'Josefin Sans', sans-serif;
}

.about-stats {
    background: rgba(217,217,217,0.2);
    padding: 0.5rem 2rem;
}

.about-summary {
    color: white;
    padding: 1.5rem;
}

.about-stats-row {
    opacity: 1;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
}

.single-stat {
    padding: 0.75rem;
    display: flex;
    flex-flow: column wrap;
    font-weight: bold;
}

.statnum {
    font-size:xx-large;
    font-weight: bold;
    margin: 0;
    padding: 0;
    line-height: 1.75rem;
}

.stattype {
    margin: 0;
    padding: 0;
    color: #DC6F0B;
}

.section-divider {
    background: linear-gradient(#484848, #000000);
    background: url("./Assets/gun_metal.webp");
    padding:0;
    width:100%;
}

.video-section {
    color:white;
    padding:0;
}

.statistics {
    color:white;
}

.contact-me {
    color:white
} 


@media only screen and (min-width: 600px) {
    .about-photo {
        width: 45%;
        position: relative;
        left: 50%;
        min-height: 30rem;
    }

    .navbar a {
        color: white;
    }

    .about-stats {
        position: absolute;
        top: 40%;
        right: 45%;
    }

    .about-header {
        position: absolute;
        top: 25%;
        right: 52%;

    }
}